.carousel .thumb img {
  width: 100%;
  height: 100%;
}

.carousel .slide img {
  max-height: 600px;
}
.carousel_component {
  position: relative;
}
.banner_content {
  position: absolute;
  top: 20%;
  width: 50%;
  color: white;
  font-family: "Inter", sans-serif;
  margin-left: 3%;
  left: 0;
}
.banner_content h1 {
  font-size: 90px;
  text-align: left;
}
.banner_content p {
  font-size: 18px;
  line-height: 32px;
  text-align: left;
}
.banner_content button {
  padding: 19px 54px;
  background-color: #ffffff;
  color: #000;
  border: none;
  font-size: 18px;
  font-family: "Inter", sans-serif;
  text-align: left;
  margin: 15px 0px 0px;
}
.carousel_button {
  text-align: left;
}
.feature_product {
  width: 100%;
  padding-top: 10%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.products_header {
  display: flex;
  width: 90%;
  justify-content: space-between;
  font-family: "Inter", sans-serif;
  margin: 0;
}
.products_header span {
  font-size: 50px;
}
.products_header h3 {
  font-size: 20px;
  font-weight: bold;
}
.feature_content {
  margin-top: 3%;
  width: 90%;
  display: flex;
  justify-content: space-between;
}
.product_content {
  height: 400px;
  border: 1px solid #c7c0c0;
  width: 23%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.pro_image {
  height: 80%;
  padding-top: 2%;
  width: 80%;
}
.pro_image img {
  height: 70%;
  padding-top: 2%;
  width: 80%;
}

.product_content span {
  font-size: 20px;
  text-align: left;
  line-height: 35px;
  color: #191919;
  height: 15%;
}
.product_content p {
  font-size: 32px;
  line-height: 65px;
  color: #6995b1;
  height: 15%;
}
.main_collection {
  height: auto;
  margin-top: 5%;
  width: 100%;
  display: flex;
  justify-content: center;
}
.collection_all {
  width: 90%;
  display: flex;
  justify-content: space-between;
}
.collection_first {
  width: 60%;
  border-radius: 10px;
  position: relative;
}
.content_collection {
  position: absolute;
  top: 30%;
  width: 50%;
  font-family: "Inter", sans-serif;

  right: 0;
}
.content_collection span {
  font-size: 18px;
  color: #8d8d8d;
  text-transform: capitalize;
}
.collection_heading {
  font-size: 79px;
  text-transform: capitalize;
  font-weight: 1000;
}
.content_collection p {
  font-size: 18px;
  color: #8d8d8d;
  width: 50%;
  text-align: justify;
}
.collection_second {
  width: 35%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.collection_first img {
  height: 100%;
  width: 100%;
  border-radius: 10px;
}
.content_collection a {
  border: solid;
  margin-top: 50px;
}
.collection_second_one,
.collection_second_two {
  width: 100%;
  height: 45%;
  border-radius: 10px;
}
.collection_second_one {
  background-image: url("../../Assets/collection-item2.jpg");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
  object-fit: cover;
  
}
.collection_second_two {
  background-image: url("../../Assets/collection-item3.jpg");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center;
  object-fit: cover;
}

.collection_second_one img,
.collection_second_two img {
  height: 100%;
  width: 100%;
  border-radius: 10px;
}
