.navigation_main {
  width: 100%;
}
.navigation {
  width: 100%;
  display: flex;
}
.header {
  width: 95%;
  display: flex;
  /* justify-content: space-between; */
  font-family: "Inter", sans-serif;
  font-size: 18px;
  line-height: 45px;
  color: rgb(141, 141, 141);
  margin-left: 3%;
}
.header_mbl {
  width: 35%;
}
.header_shipping {
  width: 60%;
}
.header_mbl > span {
  font-weight: bolder;
}
.header_logo {
  width: 8%;
  display: flex;
  justify-content: space-between;
}

nav {
  width: 100%;
  display: flex;
  height: 120px;
  font-family: "Inter", sans-serif;
  align-items: center;
  font-size: 18px;
}
.nav_logo {
  width: 38%;
}
.nav_content {
  width: 55%;
  display: flex;
  justify-content: space-between;
  font-weight: bold;
  color: #191919;
}
@media all and (max-width: 766px) {
    .header_mbl,.header_shipping{
        visibility: hidden;
    }
}
